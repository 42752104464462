<template>
  <span>{{ formatDateTime(date) }}</span>
</template>

<script>
import {DatesMixin} from "@/mixins/GeneralMixin";

export default {
  name: "DateTime",
  mixins: [DatesMixin],
  props: {
    date: String,
  }
}
</script>

<style scoped>

</style>