<template>
  <div class="font-size-md">
    <User class="mb-lg-0" :name="driver.getName()" />
    <Call v-if="showContact && driver.getTelephone()" class="mb-lg-0" :telephone="driver.getTelephone()" :show="true" />
    <Email v-if="showContact" class="mb-lg-0" :email="driver.getEmail()" :show="true" />
  </div>
</template>

<script>
import Call from "@/components/elements/Call";
import Email from "@/components/elements/Email";
import User from "@/components/elements/User";

export default {
  name: "DriverDetails",
  components: {User, Email, Call},
  props: {
    driver: Object,
    showContact: {
      type: Boolean,
      default: true
    }
  },
}
</script>

<style scoped>

</style>
