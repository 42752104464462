<template>
  <Modal :with-footer="isActionable" :name="$t(details.title, {name: deliveryNote.getName()})" @closemodal="$emit('closemodal')" :dismissible="true">
    <template v-slot:default>

      <dl class="row mb-0">
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('deliverynotes.list.driver')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11">
          <DriverDetails :driver="deliveryNote.getDriver()" />
        </dd>
        <dt v-if="isActionable" class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('deliverynotes.list.delivery_estimation')}}</dt>
        <dt v-else class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('deliverynotes.list.delivered_at')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11">
          <DayTimeEstimation v-if="isActionable" :moment="activeCycle && activeCycle.getDeliverOrder()" />
          <Date v-else :date="deliveryNote.getDeliveredAt()" />
        </dd>
        <dt class="col-4 col-md-2 mb-1 col-xl-1 font-weight-semibold">{{$t('deliverynotes.list.status')}}</dt>
        <dd class="col-8 col-md-10 mb-1 col-xl-11">
          <Status :status="deliveryNote.getStatus()"/>
        </dd>
      </dl>
      <br class="clearfix"/>
      <br class="clearfix"/>
      <p v-if="isActionable">{{ $t(details.body) }}</p>
      <div v-if="isActionable" v-bind:class="!copied ? 'alert-info':'alert-success'" class="alert alert-styled-left border-top-0 border-bottom-0 border-right-0">
        <span v-if="isScreenLgWidth" class="font-weight-semibold text-wrap">{{link}}</span>
        <span v-else class="font-weight-semibold text-wrap">{{ $t(copyText) }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <Button v-clipboard:success="onCopy" v-clipboard:copy="link" type="copy" :name="$t('deliverynotes.buttons.copy')"/>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
import Button from "@/components/elements/Button";
import Status from "@/components/elements/Status";
import {AuthorizationMixin, ScreenSizeMixin} from "@/mixins/GeneralMixin";
import DayTimeEstimation from "@/components/elements/DayTimeEstimation";
import DriverDetails from "@/components/deliverynote/frontoffice/DriverDetails";
import Date from "@/components/elements/Date";

export default {
  name: "DeliveryNoteStatusModal",
  mixins: [ScreenSizeMixin, AuthorizationMixin],
  components: {Date, DriverDetails, DayTimeEstimation, Status, Modal, Button},
  emits: ['closemodal'],
  props: {
    deliveryNote: Object
  },
  data() {
    return {
      copied: false,
      copyText: 'general.url_copy',
    }
  },
  computed: {
    activeCycle: function () {
      return this.$store.state.cycle;
    },
    isActionable: function () {
      return !this.deliveryNote.isDelivered() && this.deliveryNote.hasAccessByUser(this.loggedInUser);
    },
    link: function () {
      let routeName = 'frontoffice.breadcrumb.drivers.collect';
      if (this.deliveryNote.isInProgress()) {
        routeName = 'frontoffice.breadcrumb.drivers.deliver';
      }

      let props = this.$router.resolve({
        name: routeName,
        params: { noteId: this.deliveryNote.getId() },
      });

      return window.location.origin + props.href;
    },
    details: function () {

      if (this.deliveryNote.isNew()) {
        return {body: 'deliverynotes.modal.status.new', title: 'deliverynotes.modal.title.new'};
      }

      if (this.deliveryNote.isInProgress()) {
        return {body: 'deliverynotes.modal.status.in_progress', title: 'deliverynotes.modal.title.in_progress'};
      }

      if (this.deliveryNote.isDelivered()) {
        return {body: 'deliverynotes.modal.status.delivered', title: 'deliverynotes.modal.title.delivered'};
      }

      return {body: "", title: ""};
    },
  },
  methods: {
    onCopy: function () {
      this.copied = true;
      this.copyText = "general.url_copied";
    },
  }
}
</script>

<style scoped>

</style>