<template>
  <div>
    <a v-if="show" v-bind:href="'mailto:'+email">
      <div class="d-flex align-items-center">
        <i class="icon-envelop mr-3"></i>
        <div>{{ email || $t('general.loading') }}</div>
      </div>
    </a>
    <a v-else v-bind:href="'mailto:'+email"><i class="icon-envelop"></i></a>
  </div>
</template>

<script>
export default {
  name: "Email",
  props: {
    email: String,
    show: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
a {
  color: inherit;
  text-decoration: none;
}
</style>
