<template>
  <DeliveryNoteModal @closemodal="closeDeliveryModal" v-if="activeCycle && showDeliveryModal" />
  <DeliveryNoteStatusModal @closemodal="closeDeliveryNoteStatusModal" v-if="deliveryNote" :delivery-note="deliveryNote" />
  <div class="row">
    <div class="col-md-12">
      <Card title="deliverynotes.list.title" :with-footer="canShowFooter">
        <template v-slot:subtitle>
          <span v-if="activeCycle" class="d-block font-size-lg text-muted mb-2"> {{$t('deliverynotes.list.subtitle', {order_batch: formatMomentDate(activeCycle.getOrderBatch())})}}</span>
        </template>
        <template v-slot:default>
          <DeliveryNoteTable @show="showDeliveryNoteStatusModal" @page="getDeliveryNotes" :elements="deliveryNotes"></DeliveryNoteTable>
        </template>
        <template v-slot:footer>
          <div>
            <Button v-if="activeCycle && hasFarms && canCreateDeliveryNote" class="mr-2" @click="showDeliveryModal=true" type="confirm" name="deliverynotes.buttons.create"/>
            <span v-else-if="!hasFarms">{{ $t('deliverynotes.buttons.create_info_no_farms') }}</span>
            <span v-else-if="!activeCycle">{{ $t('deliverynotes.buttons.create_info_no_cycle') }}</span>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from "@/components/elements/Card";
import Button from "@/components/elements/Button";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import {AuthorizedActionsMixin, DatesMixin, ScreenSizeMixin} from "@/mixins/GeneralMixin";
import LoadableCollection from "@/entities/LoadableCollection";
import Pagination from "@/entities/Pagination";
import DeliveryNote from "@/entities/DeliveryNote";
import DeliveryNoteTable from "@/components/deliverynote/DeliveryNoteTable";
import DeliveryNoteModal from "@/components/deliverynote/DeliveryNoteModal";
import DeliveryNoteStatusModal from "@/components/deliverynote/DeliveryNoteStatusModal";

export default {
  name: "DeliveryNote",
  components: {DeliveryNoteModal, DeliveryNoteTable, Button, Card, DeliveryNoteStatusModal},
  mixins: [LoadingModalMixin, ScreenSizeMixin, AuthorizedActionsMixin, DatesMixin, AuthorizedActionsMixin],
  data: function () {
    return {
      deliveryNotes: new LoadableCollection(),
      showDeliveryModal: false,
      hasFarms: false,


      deliveryNote: null, //used for status modal
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    isDeliveryNoteListRoute()
    {
      return this.$router.currentRoute.value.name === 'general.breadcrumb.deliverynotes.list';
    },
    activeCycle: function ()
    {
      return this.$store.state.cycle;
    },
    canShowFooter: function ()
    {
      return !this.isLoading && (this.canCreateDeliveryNote || !this.activeCycle || !this.hasFarms);
    }
  },
  methods: {
    onLoadingComplete()
    {
      if (!this.initialise) {
        return;
      }
      this.init();
    },
    showDeliveryNoteStatusModal(deliveryNote)
    {
      if (null === deliveryNote) {
        return;
      }

      this.deliveryNote = deliveryNote;
    },
    closeDeliveryNoteStatusModal: function ()
    {
      this.deliveryNote = null;
    },
    closeDeliveryModal()
    {
      this.init();
      this.showDeliveryModal = false;
    },
    reset() {
      this.deliveryNotes.unloadAndEmpty();
    },
    init() {
      this.reset();
      this.getFarmersTotal()
    },
    getFarmersTotal: function() {
      let that = this;

      this.queueJob();
      this.axios.get(this.$store.state.config.getFarmersTotalUri()).then(
          response => {
            let content = response.data;
            that.hasFarms = content.data > 0;
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
        if (that.hasFarms) {
          that.getDeliveryNotes();
        }
      });
    },
    hydrateDeliveryNotes(deliveryNotes, filter, pageNr = 1) {
      let that = this;
      let localDeliveryNotes = new LoadableCollection();
      let filterParams = {
        page: pageNr,
        per_page: 5,
      };

      this.queueJob();
      this.axios.get(this.$store.state.config.getDeliveryNotesUri(), {params: filterParams}).then(
          response => {
            let content = response.data;
            localDeliveryNotes.setPagination(
                new Pagination(
                    content.data.current_page,
                    content.data.total,
                    content.data.per_page,
                    content.data.last_page
                )
            );

            content.data.data.forEach(function (deliveryNote) {
              let localDeliveryNote = new DeliveryNote(deliveryNote);
              localDeliveryNote.setLoaded(true);
              localDeliveryNotes.push(localDeliveryNote);
            });

            localDeliveryNotes.loaded();
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        if (localDeliveryNotes.isLoaded()) {
          deliveryNotes.empty();
          deliveryNotes.setPagination(localDeliveryNotes.getPagination());
          localDeliveryNotes.getItems().forEach(user => deliveryNotes.push(user));
          deliveryNotes.loaded();
        }
        that.completeJob();
      });
    },
    getDeliveryNotes: function (page = 1) {
      this.hydrateDeliveryNotes(this.deliveryNotes, {}, page);
    },
  }
}
</script>

<style scoped>

</style>