<template>
  <div class="d-flex align-items-center">
    <i class="icon-user mr-3"></i>
    <div>{{ name || $t('general.loading') }}</div>
  </div>
</template>

<script>
export default {
  name: "User",
  props: {
    name: String,
  }
}
</script>

<style scoped>
a {
  color: #333;
  text-decoration: none;
}
</style>
