<template>
  <Table :pagination="pagination">
    <template v-slot:header>
      <TableElementItem :centered="true" :is-header="true" v-for="header in headers" v-bind:key="header">
        <template v-slot:default>
          {{ $t(header) }}
        </template>
      </TableElementItem>
    </template>
    <template v-if="!elements.isEmpty()">
      <TableElement @click="$emit('show', element)" :class="{even: element.getId() % 2 === 0, odd:element.getId() % 2 !== 0}" v-for="element in elements.getItems()" v-bind:key="element.getId()">
        <TableElementItem :centered="true">
          <template v-slot:default>
            {{ element.getName() }}
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <Status :status="element.getStatus()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true">
          <template v-slot:default>
            <DateTime :date="element.getCreatedAt()" />
          </template>
        </TableElementItem>
        <TableElementItem :centered="true" class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Button v-if="isActionable(element)" @click.stop="showStatusModal(element)" type="eye" name="deliverynotes.buttons.show"></Button>
          </template>
        </TableElementItem>
      </TableElement>
    </template>
    <template v-slot:footer v-if="elements.isEmpty()" >
      <TableElement :size="headers.length" :empty-message="$t('deliverynotes.list.empty')" />
    </template>
  </Table>
</template>

<script>
import Table from "@/components/elements/Table";
import {ScreenSizeMixin, NumbersMixin, AuthorizationMixin} from "@/mixins/GeneralMixin";
import TableElementItem from "@/components/elements/TableElementItem";
import TableElement from "@/components/elements/TableElement";
import Status from "@/components/elements/Status";
import DateTime from "@/components/elements/DateTime";
import Button from "@/components/elements/Button";

export default {
  name: "DeliveryNoteTable",
  mixins: [ScreenSizeMixin, NumbersMixin, AuthorizationMixin],
  emits: ['confirm', 'details', 'show'],
  components: {Button, DateTime, Status, TableElement, TableElementItem, Table},
  props: {
    elements: Object
  },
  data() {
    return {
      allHeaders: [
        'deliverynotes.list.name',
        'deliverynotes.list.status',
        'deliverynotes.list.created_at',
        'deliverynotes.list.action',
      ],
      responsiveHeaders: [
        'deliverynotes.list.name',
        'deliverynotes.list.status',
        'deliverynotes.list.created_at',
        'deliverynotes.list.action',
      ],
    }
  },
  computed: {
    headers: function () {
      if (this.isScreenLgWidth) {
        return this.allHeaders;
      }

      return this.responsiveHeaders;
    },
    pagination: function () {
      return this.elements.getPagination();
    },
  },
  methods: {
    isActionable: function (deliveryNote) {
      return !deliveryNote.isDelivered() && deliveryNote.hasAccessByUser(this.loggedInUser);
    },
    showStatusModal: function (deliveryNote) {
      this.$emit('show', deliveryNote);
    },
  }
}
</script>

<style scoped>

</style>