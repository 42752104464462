import Loadable from "@/entities/Loadable";
import Collection from "@/entities/Collection";
import Shipment from "@/entities/Shipment";
import Driver from "@/entities/Driver";

export default class DeliveryNote extends Loadable {

    deliveryNote;
    shipments;
    order_shipments;

    constructor(deliveryNote = null) {
        super();
        this.shipments = new Collection();
        this.order_shipments = new Collection();
        if (null !== deliveryNote) {
            this.initFromDeliveryNote(deliveryNote);
        }
    }

    initFromDeliveryNote(deliveryNote)
    {
        this.deliveryNote = deliveryNote;
        if (deliveryNote.shipments) {
            deliveryNote.shipments.forEach((shipment) => this.shipments.push(new Shipment(shipment)));
        }
        if (deliveryNote.order_shipments) {
            deliveryNote.order_shipments.forEach((shipment) => this.order_shipments.push(new Shipment(shipment)));
        }
        this.driver = deliveryNote.driver ? new Driver(deliveryNote.driver): null;
    }

    getDeliveredAt()
    {
        return this.deliveryNote.delivered_at;
    }

    getDriver()
    {
        return this.driver;
    }

    getId()
    {
        return this.deliveryNote.id;
    }

    getName()
    {
        return this.deliveryNote.name;
    }

    getStatus()
    {
        return this.deliveryNote.status;
    }

    getCreatedAt()
    {
        return this.deliveryNote.created_at;
    }

    hasBatchShipments()
    {
        return this.getBatchShipments().length > 0;
    }

    hasOrderShipments()
    {
        return this.getOrderShipments().length > 0;
    }

    getBatchShipments()
    {
        return this.shipments.getItems().filter((shipment) => shipment.isBatch());
    }

    getOrderShipments()
    {
        return this.order_shipments.getItems();
    }

    getOrderShipmentsForDelivery()
    {
        return this.order_shipments.getItems().filter((item) => item.getOrder().isCollectedOrDelivered());
    }

    isCollectionFinished()
    {
        return this.getBatchShipments().filter((shipment) => !shipment.isCollected()).length === 0;
    }

    isDeliveryFinished()
    {
        return this.getOrderShipments().filter((shipment) => !shipment.isDelivered()).length === 0;
    }

    isFirstBatchShipment(shipment)
    {
        let shipments = this.getBatchShipments();
        return shipment.getId() === shipments[0].getId();
    }

    isLastBatchShipment(shipment)
    {
        let shipments = this.getBatchShipments();
        return shipment.getId() === shipments[shipments.length-1].getId();
    }

    isFirstOrderShipment(shipment)
    {
        let shipments = this.getOrderShipments();
        return shipment.getId() === shipments[0].getId();
    }

    isLastOrderShipment(shipment)
    {
        let shipments = this.getOrderShipments();
        return shipment.getId() === shipments[shipments.length-1].getId();
    }

    _computeShipment(shipments, shipment = null, filterCondition = null, isNext = true)
    {
        let total = shipments.length-1;
        let pivot = 0;

        if (total < 0) {
            return null;
        }

        if (null === shipment) {
            shipment = shipments[pivot];
        }

        for (let i = 0; i <= total; i++) {
            if (shipments[i].getId() == shipment.getId()) {
                pivot = i;
                break;
            }
        }

        let next = Math.min(pivot + 1, total);
        let prev = Math.max(pivot - 1, 0);

        if (!filterCondition || !filterCondition(shipment)) {
            return shipment;
        }

        if (isNext && next === total) {
            return shipments[total];
        }

        if (isNext) {
            return this._computeShipment(shipments, shipments[next], filterCondition);
        }

        return this._computeShipment(shipments, shipments[prev], filterCondition, false);
    }

    getNextBatchShipment(shipment = null, filterCondition = null)
    {
        let shipments = this.getBatchShipments();
        return this._computeShipment(shipments, shipment, filterCondition);
    }

    getNextOrderShipment(shipment = null, filterCondition = null)
    {
        let shipments = this.getOrderShipmentsForDelivery();
        return this._computeShipment(shipments, shipment, filterCondition);
    }

    getPrevBatchShipment(shipment = null, filterCondition = null)
    {
        let shipments = this.getBatchShipments();
        return this._computeShipment(shipments, shipment, filterCondition, false);
    }

    getPrevOrderShipment(shipment = null, filterCondition = null)
    {
        let shipments = this.getOrderShipmentsForDelivery();
        return this._computeShipment(shipments, shipment, filterCondition, false);
    }

    getShipmentForBatch(id)
    {
        let shipments = this.getBatchShipments().filter((shipment) => shipment.getBatchId() == id);
        if (shipments.length > 0) {
            return shipments[0];
        }

        return null;
    }

    getShipmentForOrder(id)
    {
        let shipments = this.getOrderShipments().filter((shipment) => shipment.getOrderId() == id);
        if (shipments.length > 0) {
            return shipments[0];
        }

        return null;
    }

    isNew()
    {
        return this.getStatus() === 'new';
    }

    isInProgress()
    {
        return this.getStatus() === 'in_progress';
    }

    isDelivered()
    {
        return this.getStatus() === 'delivered';
    }

    hasAccessByUser(user)
    {
        if (!user.hasAccess(["driver"])) {
            return true;
        }
        return this.getDriver().getUserId() === user.getId();
    }
}
