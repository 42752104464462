<template>
  <div v-if="moment">
    <span class="d-block" v-if="text">{{ $t(text, {moment_date: formatMomentDate(moment)}) }}</span>
    <span v-else>{{ formatMomentDate(moment) }}</span>
    <span class="d-block" v-if="hours.length > 0">{{interval}}</span>
  </div>
  <div v-else>
    -
  </div>
</template>

<script>
import {DatesMixin} from "@/mixins/GeneralMixin";

export default {
  name: "DayTimeEstimation",
  mixins: [DatesMixin],
  props: {
    text: {
      type: String,
      default: null,
    },
    moment: {
      type: Object,
    },
    hours: {
      type: Array,
      default: function () { return [] },
    }
  },
  data: () => {
    return {
      internalHours: [],
    }
  },
  beforeMount() {
    this.internalHours = this.hours;
  },
  computed: {
    interval: function () {
      return this.extractIntervalFromHours();
    }
  },
  methods: {
    extractIntervalFromHours: function () {
      let sorted = this.sortTimes(this.internalHours);
      let min = sorted[0];
      let max = sorted[sorted.length-1];

      if (min !== max) {
        return min + ' - ' + max;
      }

      return min;
    }
  }
}
</script>

<style scoped>

</style>