<template>
  <Modal v-show="!isLoading" @closemodal="$emit('closemodal')" :with-footer="true" :dismissible="true">
    <template v-slot:default>
      <Card :title="title" :with-footer="false">
        <template v-slot:default>
          <div class="col-lg-10">
            <div class="form-group">
              <TextInput @input="setName" :value="name" :placeholder="$t('deliverynotes.modal.placeholder.name')" v-bind:class="v$.name.$error && 'error'" />
              <div class="input-errors" v-for="(error, index) of v$.name.$errors" :key="index">
                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
              </div>
            </div>
            <div class="form-group">
              <Select2 v-model="driver" @select="setDriver($event)" :options="driverOptions" :settings="driverSelect2settings" v-bind:class="v$.driver.$error && 'error'"/>
              <div class="input-errors" v-for="(error, index) of v$.driver.$errors" :key="index">
                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
              </div>
            </div>
            <div class="form-group">
              <label>{{ $t('deliverynotes.modal.placeholder.batches') }}</label>
              <Select2 v-model="batches" @select="setBatches($event)" :options="batchOptions" :settings="batchSelect2settings" v-bind:class="v$.batches.$error && 'error'"/>
              <div class="input-errors" v-for="(error, index) of v$.batches.$errors" :key="index">
                <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
              </div>
            </div>
          </div>
        </template>
      </Card>
      <NotificationAlert :notifications="notification" />
    </template>
    <template v-slot:footer>
      <Button v-bind:disabled="canDisableButtons" @click="createDeliveryNote" class="mr-auto" :name="$t('deliverynotes.buttons.create')" type="confirm"></Button>
    </template>
  </Modal>
</template>

<script>
import Card from "@/components/elements/Card";
import Modal from "@/components/elements/Modal";
import Button from "@/components/elements/Button";
import TextInput from "@/components/elements/TextInput";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import {AuthorizationMixin, ValidationsMixin, NotificationsMixin} from "@/mixins/GeneralMixin";
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import NotificationAlert from "@/components/elements/NotificationAlert";
import Select2 from 'vue3-select2-component';
import LoadableCollection from "@/entities/LoadableCollection";
import Driver from "@/entities/Driver";
import Batch from "@/entities/Batch";

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  name: "DeliveryNote",
  components: {NotificationAlert, TextInput, Button, Modal, Card, Select2},
  mixins: [LoadingModalMixin, AuthorizationMixin, ValidationsMixin, NotificationsMixin],
  emits: ['closemodal'],
  validations: {
    name: {
      required
    },
    batches: {
      required,
    },
    driver: {
      required,
    },
  },
  data: function() {
    let that = this;
    return {
      name: "",
      batches: [],
      driver: "",

      batchOptions: new LoadableCollection(),
      driverOptions: new LoadableCollection(),

      batchSelect2settings: {
        placeholder: this.$i18n.t('deliverynotes.modal.placeholder.batches'),
        width: '100%',
        dropdownAutoWidth: true,
        multiple: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
          maximumSelected: () => that.$i18n.t("general.select.maxselection"),
        },
      },
      driverSelect2settings: {
        placeholder: this.$i18n.t('deliverynotes.modal.placeholder.driver'),
        width: '100%',
        dropdownAutoWidth: true,
        theme: 'bootstrap',
        language: {
          noResults: () => that.$i18n.t("general.select.no_results"),
        },
      },
    }
  },
  beforeMount() {
    this.getBatches();
    this.getDrivers();
  },
  computed: {
    title: function () {
      return this.$t("deliverynotes.modal.create_title");
    },
    isFormReady: function () {
      return !(this.v$.driver.$error || this.v$.batches.$error || this.v$.name.$error);
    },
    canDisableButtons: function () {
      return !this.isFormReady;
    }
  },
  methods: {
    getBatches: function (page = 1) {
      this.hydrateBatches({status: ['collection_ready', 'packing']}, page);
    },
    getDrivers: function (page = 1) {
      this.hydrateDrivers({}, page);
    },
    hydrateDrivers(filter, pageNr = 1) {
      let that = this;
      let filterParams = {
        page: pageNr,
      };

      this.driverOptions = [];
      this.queueJob();
      this.axios.get(this.$store.state.config.getDriversUri(), {params: filterParams}).then(
          response => {
            let content = response.data;
            content.data.data.forEach(function (data) {
              let driver = new Driver(data);
              that.driverOptions.push({
                id: driver.getId(),
                text: driver.getName(),
              });
            });
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    },
    hydrateBatches(filter, pageNr = 1) {
      let that = this;
      let filterParams = {
        page: pageNr,
      };

      if (typeof filter.status !== "undefined") {
        filterParams.status = filter.status;
      }

      this.batchOptions = [];
      this.queueJob();
      this.axios.get(this.$store.state.config.getBatchesUri(), {params: filterParams}).then(
          response => {
            let content = response.data;

            content.data.data.forEach(function (data) {
              let batch = new Batch(data);
              if (!batch.hasShipment()) {
                that.batchOptions.push({
                  id: batch.getId(),
                  text: batch.getFarm().getName() + " - " + that.$i18n.t('deliverynotes.batch_nr', {nr: batch.getId()})
                })
              }
            });
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    },
    onLoadingComplete: function ()
    {
      setTimeout(function () {
        document.getElementsByTagName("body")[0].classList.add('modal-open');
      }, 2000);
    },
    setName: function (reference, value)
    {
      this.name = value;
      this.v$.name.$touch();
    },
    setBatches()
    {
      this.v$.batches.$touch();
    },
    setDriver: function ()
    {
      this.v$.driver.$touch();
    },
    createDeliveryNote: function ()
    {
      let that = this;

      this.v$.batches.$touch();
      this.v$.driver.$touch();
      this.v$.name.$touch();

      if (!this.isFormReady) {
        return;
      }

      let data = {
        'name': this.name,
        'batches': this.batches.map( (id) => parseInt(id)),
        'driver': parseInt(this.driver),
      }

      this.queueJob();
      this.resetNotifications();
      this.$store.commit("skipResponseCheckForStatusCode", 404);
      this.axios.post(this.$store.state.config.getDeliveryNotesUri(), data).then(
          () => {
            that.$emit('closemodal');
          }
      ).catch(
          error => {
            console.log(error);
            if (error.response && error.response.data.messages) {
              error.response.data.messages.forEach(message => that.addWarningNotification(message));
            }
          }
      ).finally(function () {
        that.completeJob(true);
      });
    },
  },
}
</script>

<style scoped>

</style>